import { FaFacebookF, FaTwitter, FaMediumM, FaTelegram } from "react-icons/fa";

export const socialLinks = [
  // {
  //   id: 'github',
  //   label: 'Github',
  //   link: '',
  //   icon: <FaGithub />
  // },
  {
    id: 'twitter',
    label: 'Twitter',
    link: 'https://twitter.com/fortdexfinance',
    icon: <FaTwitter />
  },
  {
    id: 'telegram',
    label: 'Telegram',
    link: 'https://t.me/fortdexann',
    icon: <FaTelegram />
  },
  // {
  //   id: 'discord',
  //   label: 'Discord',
  //   link: '',
  //   icon: <FaDiscord />
  // },
  {
    id: 'medium',
    label: 'Medium',
    link: 'https://medium.com/fortdex',
    icon: <FaMediumM />
  },
  {
    id: 'facebook',
    label: 'Facebook',
    link: 'https://facebook.com/Fortdex-106336178324472',
    icon: <FaFacebookF />
  },
];

export const allRightText = '©2021 FortDex team. All rights reserved.'