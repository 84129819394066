import React from 'react';
import Modal from 'react-modal';
import 'components/WrongNetworkModal/index.scss';
import { ReactComponent as CloseIcon } from 'assets/images/close.svg';

const customStyles = {
  overlay: {
    backgroundColor: 'rgb(152, 177, 240, 0.5)',
  },
  content: {
    height: '100%',
  },
};

export const WrongNetworkModal = (props) => {
  const { isOpen, closeModal } = props;

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      className="wrong-network-modal"
      ariaHideApp={false}
    >
      <div className="wrong-network-wrapper">
        <div className="title">
          <div>Wrong Network</div>
          <CloseIcon
            onClick={() => closeModal()}
            style={{ cursor: 'pointer ' }}
          />
        </div>
        <div className="warning-text">
          Please connect to the appropriate Ethereum network.
        </div>
      </div>
    </Modal>
  );
};
