import React, { useCallback, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled, { keyframes } from 'styled-components';
import 'components/Header/header.scss';
import classNames from 'classnames';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { ConnectModal } from '../ConnectModal';
import { useWeb3React } from '@web3-react/core';
import { formatAddress } from 'utils';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import useQueryParams from '../../hooks/useQueryParams';
import { WalletDetailModal } from '../WalletDetailModal';

const SubMenu = ({ menu }) => {
  const history = useHistory();
  return (
    <div className="submenu">
      <div className="menu_text">{menu.title}</div>
      <div className="menu-dropdown">
        {menu.items.map((item, idx) => {
          return (
            <div key={idx} className="menu-dropdown-item">
              {item.href ? (
                <Link
                  className="menu_text"
                  to={{ pathname: item.href }}
                  target={item.target}
                >
                  {item.title}
                </Link>
              ) : (
                <div
                  className="menu-text"
                  onClick={() => history.push(`/${menu.path}`)}
                >
                  {menu.title}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const Header = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [isWalletDetailModalOpen, setIsWalletDetailModalOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const queries = useQueryParams();
  const [isOpen, setIsOpen] = useState(true);
  const mainLocation = location.pathname.split('/');
  const { account, active } = useWeb3React();

  const openWalletDetailModal = useCallback(() => {
    setIsWalletDetailModalOpen(true);
  }, []);

  const closeWalletDetailModal = useCallback(() => {
    setIsWalletDetailModalOpen(false);
  }, []);

  const { menus } = useStoreState((state) => state.header);
  const { connectModalState } = useStoreState((state) => state.connectModal);
  const { changeConnectModalState } = useStoreActions(
    (state) => state.connectModal,
  );
  const openConnectModal = useCallback(() => {
    changeConnectModalState(true);
  }, [changeConnectModalState]);
  const closeConnectModal = useCallback(() => {
    changeConnectModalState(false);
  }, [changeConnectModalState]);

  if (queries.get('prompt') === '1') {
    openConnectModal();
    history.push('/home', location.state);
  }

  return (
    <div className="header">
      <ConnectModal
        isOpen={connectModalState}
        closeModal={closeConnectModal}
        redirectTo={location.state && location.state.r}
      />
      <WalletDetailModal
        isOpen={isWalletDetailModalOpen}
        closeModal={closeWalletDetailModal}
      />
      <div className="left_panel" onClick={() => history.push('/home')}>
        <Logo style={{ marginRight: 11 }} />
        FORTDEX
      </div>
      <div
        className="menu_panel"
        style={isOpen || !isMobile ? { display: 'flex' } : { display: 'none' }}
      >
        {menus.map((menu, index) => {
          return menu.href ? (
            <Link
              className="menu_text"
              to={{ pathname: menu.href }}
              target={menu.target}
            >
              {menu.title}
            </Link>
          ) : menu.items && menu.items.length ? (
            <SubMenu menu={menu} />
          ) : (
            <div
              className={
                menu.path === mainLocation[1]
                  ? classNames('menu_text', 'menu_text_selection')
                  : 'menu_text'
              }
              key={`${menu.path}-${index}`}
              onClick={() => history.push(`/${menu.path}`)}
              style={index === menus.length - 1 ? {} : { marginRight: 36 }}
            >
              {menu.title}
            </div>
          );
        })}
      </div>
      <div className="right_panel">
        <div
          className="connect_btn"
          onClick={!active ? openConnectModal : openWalletDetailModal}
        >
          {active ? formatAddress(account) : 'Connect Wallet'}
        </div>
        <div
          className="btn-bugger"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <BurgerIcon />
        </div>
      </div>
    </div>
  );
};

const BuggerIconContainer = styled.div`
  position: relative;
  height: 18px;
  width: 22px;
  cursor: pointer;
  align-self: center;
  margin-right: 10px;
`;
const BurgerSpan = styled.span`
  position: absolute;
  display: block;
  height: 2px;
  width: 100%;
  max-width: 22px;
  border-radius: 2px;
  background: black;
  top: ${(props) => props.top || 0}px;
  animation: ${(props) => props.animation} 0.5s linear normal;
  animation-fill-mode: forwards;
  ${(props) => props.transform}
`;
const BurgerTopIn = keyframes`
0% {
  left: -5px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

20% {
  left: -5px;
  -webkit-transform: rotate(-60deg);
  transform: rotate(-60deg);
}
80% {
  left: 0;
  -webkit-transform: rotate(15deg);
  transform: rotate(15deg);
}
100% {
  left: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
`;
const BurgerTopOut = keyframes`
0% {
  left: 0;
  top: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
20% {
  left: 0;
  top: 0;
  -webkit-transform: rotate(15deg);
  /* transform: rotate(15deg); */
}
80% {
  left: -5px;
  top: 0;
  -webkit-transform: rotate(-60deg);
  transform: rotate(-60deg);
}
100% {
  left: -5px;
  top: 1px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
`;
const BurgerBottomIn = keyframes`
0% {
  left: -5px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
20% {
  left: -5px;
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
}
80% {
  left: 0;
  -webkit-transform: rotate(-15deg);
  transform: rotate(-15deg);
}
100% {
  left: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
`;
const BurgerBottomOut = keyframes`
0% {
  left: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

20% {
  left: 0;
  -webkit-transform: rotate(-15deg);
  transform: rotate(-15deg);
}
80% {
  left: -5px;
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
}
100% {
  left: -5px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
`;
const BurgerFillingIn = keyframes`
0% {
  width: 0;
  left: 36px;
}
40% {
  width: 0;
  left: 40px;
}
80% {
  width: 36px;
  left: -6px;
}
100% {
  width: 22px;
  left: 0px;
}
`;
const BurgerFillingOut = keyframes`
0% {
  width: 36px;
  left: 0px;
}
20% {
  width: 42px;
  left: -6px;
}
40% {
  width: 0;
  left: 40px;
}
100% {
  width: 0;
  left: 36px;`;
export const BurgerIcon = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <BuggerIconContainer onClick={() => setIsOpen(!isOpen)}>
      <BurgerSpan
        top={0}
        animation={!isOpen ? BurgerTopIn : BurgerTopOut}
        transform={isOpen ? 'transform-origin: 22px 0px;' : ''}
      />
      <BurgerSpan
        top={8}
        animation={!isOpen ? BurgerFillingIn : BurgerFillingOut}
      />
      <BurgerSpan
        top={16}
        animation={!isOpen ? BurgerBottomIn : BurgerBottomOut}
        transform={isOpen ? 'transform-origin: 22px 2px;' : ''}
      />
    </BuggerIconContainer>
  );
};
