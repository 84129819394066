import React from 'react';
import 'components/Footer/footer.scss';
import { socialLinks, allRightText } from 'constants/footer.constants';
import { useWeb3React } from '@web3-react/core';
import {
  CHAIN_IDS,
  CONTRACT_LINK,
  MAINNET,
} from '../../constants/contract-address.constants';

export const Footer = () => {
  const { chainId } = useWeb3React();
  const contractLink = CONTRACT_LINK[chainId || CHAIN_IDS[MAINNET]]; // default to mainnet
  const onClickLink = (link) => {
    window.open(link);
  };
  return (
    <div className="footer">
      <div className="left">{allRightText}</div>
      <div className="right">
        <div className="legal">
          <div className="title">LEGAL</div>
          <div className="detail">
            <a href={contractLink} target="_blank" rel="noopener noreferrer">
              Contract
            </a>
          </div>
          <div className="detail">
            <a href="/#" rel="noopener noreferrer">
              Privacy Policy
            </a>
          </div>
          <div className="detail">
            <a href="/risk-disclosure" rel="noopener noreferrer">
              Risk Disclosure
            </a>
          </div>
        </div>
        <div className="community">
          <div className="title">COMMUNITY</div>
          <div className="logo">
            {socialLinks.map((link, index) => {
              return (
                <div
                  className="link"
                  style={
                    index !== 0
                      ? {
                          marginLeft: 20,
                        }
                      : {}
                  }
                  key={`${link.label}-${index}`}
                  onClick={() => onClickLink(link.link)}
                >
                  {link.icon}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
