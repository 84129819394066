import { Contract } from '@ethersproject/contracts';
import { getAddress } from '@ethersproject/address';
import { AddressZero } from '@ethersproject/constants';

export const isAddress = (value) => {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

const ETHERSCAN_PREFIXES = {
  1: '',
  3: 'ropsten.',
  4: 'rinkeby.',
  5: 'goerli.',
  42: 'kovan.'
}

export const getEtherscanLink = (
  chainId,
  data,
  type
) => {
  const prefix = `https://${ETHERSCAN_PREFIXES[chainId] || ETHERSCAN_PREFIXES[1]}etherscan.io`

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`;
    }
    case 'token': {
      return `${prefix}/token/${data}`;
    }
    case 'block': {
      return `${prefix}/block/${data}`;
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`;
    }
  }
}

export const getUniswapLink = (
  chainId,
  type,
  token1,
  token2,
) => {
  const prefix = 'https://app.uniswap.org/#';
  switch (type) {
    case 'liquidity': {
      return `${prefix}/add/${token1}/${token2}`;
    }
    default: {
      return `${prefix}/add/${token1}/${token2}`;
    }
  }
};

export const getSigner = (library, account) => {
  return library.getSigner(account).connectUnchecked();
}

export const getProviderOrSigner = (library, account) => {
  return account ? getSigner(library, account) : library;
}

export const getContract = (address, ABI, library, account) => {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }
  return new Contract(address, ABI, getProviderOrSigner(library, account));
};

export const formatAddress = (address) => {
  return [
    ...address.slice(0, 6),
    '...',
    ...address.slice(address.length - 4, address.length),
  ].join('');
};

export const formatThousand = (num) => {
  let num_parts = num.toString().split(".");
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return num_parts.join(".");
}

export const getCountdown = (destination) => {
  // get total seconds between the times
  let delta = Math.abs(destination - Date.now()) / 1000;

  // calculate (and subtract) whole days
  let days = Math.floor(delta / 86400);
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  let hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  let minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  // what's left is seconds
  let seconds = +((delta % 60).toFixed(0));  // in theory the modulus is not required

  if (days < 10) {
    days = `0${days}`
  }
  if (minutes < 10) {
    minutes = `0${minutes}`
  }
  if (hours < 10) {
    hours = `0${hours}`
  }
  if (seconds < 10) {
    seconds = `0${seconds}`
  }
  return {
    days,
    hours,
    minutes,
    seconds,
  }
}