import { action } from 'easy-peasy';

export const transactionModel = {
  transaction: {
    method: undefined,
    status: undefined,
    message: undefined,
  },
  update: action((state, payload) => {
    state.transaction = payload;
  })
};