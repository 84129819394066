import React, { useCallback, useState } from 'react';
import Modal from 'react-modal';
import 'components/WalletDetailModal/index.scss';
import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import { ReactComponent as IconCopy } from 'assets/images/icon-copy.svg';
import { ReactComponent as IconCopied } from 'assets/images/icon-copied.svg';
import { ReactComponent as IconOpenLink } from 'assets/images/icon-open-link.svg';
import { useWeb3React } from '@web3-react/core';
import { getEtherscanLink } from '../../utils';
// import { useStoreActions } from 'easy-peasy';

const customStyles = {
  overlay: {
    backgroundColor: 'rgb(152, 177, 240, 0.5)',
  },
  content: {
    height: '100%',
  },
};

export const WalletDetailModal = (props) => {
  const { isOpen, closeModal } = props;
  const { account, chainId, deactivate } = useWeb3React();
  const [copied, setCopied] = useState(false);
  const goToEtherscan = useCallback(() => {
    window
      .open(getEtherscanLink(chainId, account, 'address'), '_blank')
      .focus();
  }, [chainId, account]);
  const copy = () => {
    const el = document.createElement('input');
    el.value = account;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };
  const disconnect = () => {
    deactivate();
    closeModal();
  };

  // const { changeConnectModalState } = useStoreActions(
  //   (state) => state.connectModal,
  // );
  // const openConnectModal = useCallback(() => {
  //   changeConnectModalState(true);
  // }, [changeConnectModalState]);
  // const changeWallet = () => {
  //   disconnect();
  //   openConnectModal();
  // };
  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      className="wallet_detail_modal"
      ariaHideApp={false}
    >
      <div className="wallet_detail_wrapper">
        <div className="title">
          <div>Your wallet</div>
          <CloseIcon
            onClick={() => closeModal()}
            style={{ cursor: 'pointer ' }}
          />
        </div>
        <div className="network-wrapper">
          <div className="network-desc">Connected with Metamask</div>
          {/* <div className="change-network-btn" onClick={changeWallet}>
            <span className="change-network-text">Change</span>
          </div> */}
        </div>
        <div className="wallet-content-bg">
          <div className="address">{account}</div>
          <div style={{ display: 'flex' }}>
            <div className="copy-address">
              {copied ? (
                <div>
                  <IconCopied style={{ marginRight: '5px' }} />
                  Copied
                </div>
              ) : (
                <div onClick={copy}>
                  <IconCopy style={{ marginRight: '5px' }} />
                  Copy Address
                </div>
              )}
            </div>
            <div className="view-address" onClick={goToEtherscan}>
              <IconOpenLink style={{ marginRight: '5px' }} />
              View on Etherscan
            </div>
          </div>
        </div>
        <div className="button-wrapper">
          <div className="disconnect" onClick={disconnect}>
            Disconnect
          </div>
        </div>
      </div>
    </Modal>
  );
};
