export const menus = [
  {
    path: 'home',
    title: 'Home',
  },
  {
    path: 'stake',
    title: 'Stake',
  },
  {
    path: 'farm',
    title: 'Farm',
  },
  {
    path: 'about',
    title: 'About',
  },
  {
    title: 'Analytics',
    items: [
      {
        title: 'FORT-XSGD',
        href: 'https://v2.info.uniswap.org/pair/0x83bb779bf79f418d296839099b699f95773aae06',
        target: '_blank',
      },
      {
        title: 'FORT-USDC',
        href: 'https://v2.info.uniswap.org/pair/0xed250c8db681a41051d9c408350651cba17428d2',
        target: '_blank',
      },
    ],
  },
  // {
  //   path: 'swap',
  //   title: 'Swap',
  // },
  // {
  //   path: 'invite',
  //   title: 'Invite',
  // },
  // {
  //   path: 'announcement',
  //   title: 'Announcement',
  // },
];
