import { useEagerConnect, useInactiveListener } from 'hooks';

export default function Web3ReactManager({ children }) {
  const triedEager = useEagerConnect();

  useInactiveListener(!triedEager);

  if (!triedEager) {
    return null;
  }

  return children;
}
