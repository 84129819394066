import { createStore } from 'easy-peasy';
import { headerModel } from 'state/header.model';
import { connectModel } from 'state/connect.model';
import { transactionModel } from 'state/transaction.model';
import { enableMapSet } from 'immer';

enableMapSet();

const model = {
  header: headerModel,
  connectModal: connectModel,
  transaction: transactionModel,
};

export const store = createStore(model);