import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import 'components/ConnectModal/connect-modal.scss';
import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import { ReactComponent as MetamaskIcon } from 'assets/images/metamask.svg';
import { ReactComponent as WalletConnectIcon } from 'assets/images/walletconnect.svg';
import { ReactComponent as TorusIcon } from 'assets/images/torus.svg';
import { injected, walletconnect, torus } from 'connectors';
import { useWeb3React } from '@web3-react/core';
import BeatLoader from 'react-spinners/BeatLoader';
import { usePrevious } from 'hooks/usePrevious';
import { useHistory } from 'react-router-dom';

const customStyles = {
  overlay: {
    backgroundColor: 'rgb(152, 177, 240, 0.5)',
  },
  content: {
    height: '100%',
  },
};

const supportedConnector = [
  {
    name: 'Metamask',
    connector: injected,
    icon: <MetamaskIcon style={{ width: 30, height: 30 }} />,
  },
  {
    name: 'WalletConnect',
    connector: walletconnect,
    icon: <WalletConnectIcon style={{ width: 30, height: 30 }} />,
  },
  {
    name: 'Torus',
    connector: torus,
    icon: <TorusIcon style={{ width: 30, height: 30 }} />,
    developing: true,
  },
];

export const ConnectModal = (props) => {
  const { isOpen, closeModal, redirectTo } = props;
  const { activate, active } = useWeb3React();
  const [connecting, setConnecting] = useState(undefined);
  const history = useHistory();
  const connect = (item) => {
    setConnecting(item.name);
    activate(item.connector, (err) => {
      if (err.name === 'UserRejectedRequestError') {
        setConnecting(undefined);
      }
    }).then(() => {
      if (redirectTo) {
        history.push(redirectTo, null);
      }
    });
  };

  const activePrev = usePrevious(active);

  useEffect(() => {
    if (activePrev === false && active === true) {
      closeModal();
      setConnecting(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, activePrev]);

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      className="connect_modal"
      ariaHideApp={false}
    >
      <div className="connectors_wrapper">
        <div className="title">
          Connect to a Wallet
          <CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
        </div>
        {supportedConnector.map((connector, index) => {
          return (
            <div
              className="connectors"
              style={{
                marginBottom: index === supportedConnector.length - 1 ? 0 : 16,
              }}
              onClick={() => connect(connector)}
              key={`${connector.name}-${index}`}
            >
              <div className="connector-name">
                {connecting === connector.name ? (
                  <BeatLoader color="#FD6F3B" />
                ) : (
                  connector.name
                )}
              </div>
              <div className="icon">{connector.icon}</div>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};
