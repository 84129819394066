import React, { Suspense, lazy, useState, useEffect } from 'react';
import { StoreProvider } from 'easy-peasy';
import { Header } from 'components/Header';
import { store } from 'state/store';
import './App.scss';
import { Footer } from 'components/Footer';
import { useWeb3React, Web3ReactProvider } from '@web3-react/core';
import { providers } from 'ethers';
import Web3ReactManager from 'components/Web3ReactManager';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { WrongNetworkModal } from './components/WrongNetworkModal';
import { CHAIN_IDS } from './constants/contract-address.constants';

const DashBoard = lazy(() => import('./views/DashBoard'));
const Stake = lazy(() => import('./views/Stake'));
const Farm = lazy(() => import('./views/Farm'));
const FarmFields = lazy(() => import('./views/FarmFields'));
const About = lazy(() => import('./views/About'));
const RiskDisclosure = lazy(() => import('./views/RiskDisclosure'));

function getLibrary(provider) {
  const library = new providers.Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

const AppWrapper = () => {
  return (
    <StoreProvider store={store}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ReactManager>
          <App />
        </Web3ReactManager>
      </Web3ReactProvider>
    </StoreProvider>
  );
};

const App = () => {
  const { chainId, active } = useWeb3React();
  const [wrongNetworkModalOpen, setWrongNetworkModalOpen] = useState(false);
  // useEffect(() => {
  //   setWrongNetworkModalOpen(chainId && chainId !== 1);
  // }, [chainId]);

  if (window.ethereum && chainId !== CHAIN_IDS.rinkeby) {
    window.ethereum
      .request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${parseInt(String(CHAIN_IDS.rinkeby), 16)}` }],
      })
      .catch((err) => {
        console.error(err);
      });
  }
  return (
    <div className="App wrapper">
      <WrongNetworkModal
        isOpen={wrongNetworkModalOpen}
        closeModal={() => setWrongNetworkModalOpen(false)}
      />
      <Router>
        <Header />
        <div className="main">
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route path="/home" component={DashBoard} />
              <Route
                path="/stake"
                render={() =>
                  chainId && active ? (
                    <Stake />
                  ) : (
                    <Redirect
                      to={{
                        pathname: '/home',
                        search: '?prompt=1',
                        state: { r: 'stake' },
                      }}
                    />
                  )
                }
              />
              <Route
                path="/farm"
                exact
                render={() =>
                  chainId && active ? (
                    <FarmFields />
                  ) : (
                    <Redirect
                      to={{
                        pathname: '/home',
                        search: '?prompt=1',
                        state: { r: 'farm' },
                      }}
                    />
                  )
                }
              />
              <Route path="/farm/:poolName" component={Farm} />
              <Route path="/swap" component={DashBoard} />
              <Route path="/invite" component={DashBoard} />
              <Route path="/announcement" component={DashBoard} />
              <Route path="/about" component={About} />
              <Route path="/risk-disclosure" component={RiskDisclosure} />
              <Redirect to="/home" />
            </Switch>
          </Suspense>
        </div>
        <Footer />
      </Router>
    </div>
  );
};

export default AppWrapper;
